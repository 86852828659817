import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import { CardsThree } from "../homepage";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "./hr-chatbot";
import { useMedia } from "use-media";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const TopImage = require("../../assets/img/feature/inbox/shared_inbox_header.png");
const TopImage_mob = require("../../assets/img/feature/inbox/shared_inbox_header.png");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../assets/images/homepage/group1.png");
export const cardTwo = require("../../assets/images/homepage/group4.png");

const section1 = require("../../assets/img/feature/inbox/shared_inbox_sec_1.png");
const section2 = require("../../assets/img/feature/inbox/shared_inbox_sec_2.png");
const section3 = require("../../assets/img/feature/inbox/shared_inbox_sec_3.png");
const section4 = require("../../assets/img/feature/inbox/shared_inbox_sec_4.png");
const section5 = require("../../assets/img/feature/inbox/shared_inbox_sec_5.png");
const section6 = require("../../assets/img/feature/inbox/shared_inbox_sec_6.png");
const section7 = require("../../assets/img/feature/inbox/shared_inbox_sec_7.png");
const section8 = require("../../assets/img/feature/inbox/shared_inbox_sec_8.png");

const related1 = require("../../assets/img/feature/knowledge_ai/related_artical_1.png");
const related2 = require("../../assets/img/feature/knowledge_ai/related_artical_2.png");
const related3 = require("../../assets/img/feature/knowledge_ai/related_artical_3.png");

const one = require("../../assets/img/feature/knowledge_ai/1.svg");
const two = require("../../assets/img/feature/knowledge_ai/2.svg");
const three = require("../../assets/img/feature/knowledge_ai/3.svg");
const four = require("../../assets/img/feature/knowledge_ai/4.svg");
const five = require("../../assets/img/feature/knowledge_ai/5.svg");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const logs = require("../../assets/img/feature/updated/logs.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const c_ai = require("../../assets/img/feature/updated/c_ai.png");

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​",
    header: "Chatbot Builder​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
    new: true,
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approvals",
    header: "Chatbot Approvals​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: marketplace,
    alt: "Chatbot Workflow Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },

  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Assistant | Shared Inbox Feature"
        description="Boost team productivity with Workativ Assistant’s AI-powered shared inbox. Streamline communication and collaboration for faster issue resolution. Try it now!"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Workativ Assistant | Shared Inbox Feature"
        ogDescription="Boost team productivity with Workativ Assistant’s AI-powered shared inbox. Streamline communication and collaboration for faster issue resolution. Try it now!"
      />
      <Container
        additionalClass={"feature-display-none"}
        additionalClassParent={"indvidual_features_parent_cont"}
      >
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            image={TopImage}
            altImage={"Conversation AI Platform"}
            image_mob={TopImage_mob}
            additionalClassSection={"features_indvidual_dec"}
            additionalClassContent={"knowledge-ai-content"}
            additionalClassImage={"width-100"}
          >
            <TopFormWithImage.Header comingsoon={true}>
              Shared Inbox
            </TopFormWithImage.Header>
            <TopFormWithImage.Content>
              Deliver more resolutions faster with our AI-enhanced shared inbox
              for your teams
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {/* {isSmall ? null : <OnScrollPopup />} */}
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column" : ""
                }`}
              >
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>Multi-channel Inbox</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Our multi-channel shared chat inbox is AI-enhanced and
                    designed for speed and efficiency—so your team can work
                    smarter and collaborate faster.
                  </p>
                </div>
                <div className="shared-inbox-image-box">
                  <img
                    loading="lazy"
                    src={section1}
                    alt="Choose your knowledge source"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-grey">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column-reverse" : ""
                }`}
              >
                <div className="shared-inbox-image-box">
                  <img
                    loading="lazy"
                    src={section2}
                    alt="Customize your workflows"
                  />
                </div>
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>Actions</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Super power your live agents with workflow automatons.
                    Connect with apps and resolve time consuming tasks and
                    workflows in seconds, without ever leaving your inbox.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column" : ""
                }`}
              >
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>Team Collaborations</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Resolve complex issues quickly with inbox optimized for team
                    collaboration using @ Mentions and real-time notifications.
                  </p>
                </div>
                <div className="shared-inbox-image-box ">
                  <img
                    loading="lazy"
                    src={section3}
                    alt="Customize your workflows"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-grey">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column-reverse" : ""
                }`}
              >
                <div className="shared-inbox-image-box ">
                  <img loading="lazy" src={section4} alt="Add human touch" />
                </div>
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>AI Suggestions</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Put your knowledge base to good use. Let AI leverage your
                    content to be the perfect co-pilot for your agents using AI
                    based suggestions for faster response.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column" : ""
                }`}
              >
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>AI Summary</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Enable your agents to assist your users faster with AI
                    assisted conversation summary.
                  </p>
                </div>
                <div className="shared-inbox-image-box ">
                  <img loading="lazy" src={section5} alt="Deploy the bot" />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-grey">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column-reverse" : ""
                }`}
              >
                <div className="shared-inbox-image-box">
                  <img
                    loading="lazy"
                    src={section6}
                    alt="Customize your workflows"
                  />
                </div>
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>User Personalization</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Personalize your chats with user information such as
                    location, urgency, last interaction, category and much more.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-white p-0">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column" : ""
                }`}
              >
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>Live Agent Transfers</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Transfer complex problems from chatbot to your live support
                    teams without interruption—so your users get the best
                    possible experience.
                  </p>
                </div>
                <div className="shared-inbox-image-box ">
                  <img loading="lazy" src={section7} alt="Measure and tweak" />
                </div>
              </div>
            </div>
          </section>
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page background-grey mb-2">
            <div className="container">
              <div
                className={`d-flex shared-inbox-content-image ${
                  isSmall ? "flex-column-reverse" : ""
                }`}
              >
                <div className="shared-inbox-image-box ">
                  <img loading="lazy" src={section8} alt="Measure and tweak" />
                </div>
                <div className="shared-inbox-content-box">
                  <h3 className="font-section-header">
                    <span>Dashboard</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Real-time dashboards and monitoring views make it easy to
                    take action and optimize your team’s performance.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
          <section className="cards_features background-grey mb-60">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18 ">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </Container>
    </>
  );
}
